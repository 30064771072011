<template>
<ModalForm
    id="modalFormEditPO"
    :title="titleModal"
    :validationSchema="validationSchema"
    :initialValues="initialValues"
    :fnCallbackSubmit="handleCrudAction"
    :readOnly="readOnly"
    ref="modalForm"
>
    <!-- FOLIO -->
    <RowForm :twoColums="true">
        <template v-slot:default>
            <label class="required fs-6 fw-bold mb-2">{{ $t("FIELDS.FOLIO") }}</label>
            <Field  name="folio" v-slot="{value, field, errorMessage}">
                <el-form-item :error="errorMessage">
                    <el-input
                        class="itemControl"
                        v-bind="field"
                        type="text" 
                        placeholder="Folio"
                        :validate-event="false"
                        :disabled="true"
                        :model-value="value"
                    />
                </el-form-item>
            </Field>
        </template>
        <template v-slot:col2>
            <label class="required fs-6 fw-bold mb-2">{{ $t("FIELDS.SUPPLIER") }}</label>
            <SelectFilterRemote
                class="itemControl"
                :name="'supplierId'"
                :options="comboSupplier"
                @search="searchSuppliers"
                :disabled="readOnly"
                :placeholder="$t('FIELDS.SUPPLIER')">
            </SelectFilterRemote>
        </template>
    </RowForm>
    <RowForm :twoColums="true" class="paddingTop">
        <template v-slot:default>
            <label class="fs-6 fw-bold mb-2">{{ $t("FIELDS.SUPPLIER_FOLIO") }}</label>
            <Field  name="purchaseOrderSupplierFolio" v-slot="{value, field, errorMessage}">
                <el-form-item :error="errorMessage">
                    <el-input
                        class="itemControl"
                        v-bind="field"
                        type="text" 
                        placeholder=""
                        :validate-event="false"
                        :disabled="readOnly"
                        :model-value="value"
                    />
                </el-form-item>
            </Field>
        </template>
        <template v-slot:col2>
            <label class="fs-6 fw-bold mb-2">{{ $t("FIELDS.CREDIT_DAYS") }}</label>
            <Field  name="creditDays" v-slot="{value, field, errorMessage}">
                <el-form-item :error="errorMessage">
                    <el-input
                        class="itemControl"
                        v-bind="field"
                        type="text" 
                        placeholder=""
                        :validate-event="false"
                        :disabled="readOnly"
                        :model-value="value"
                    />
                </el-form-item>
            </Field>
        </template> 
    </RowForm>
    <RowForm :twoColums="true" class="paddingTop">
        <template v-slot:default>
            <label class="fs-6 fw-bold mb-2">{{ $t("FIELDS.FREIGHT_NUMBER") }}</label>
            <Field  name="freightNumber" v-slot="{value, field, errorMessage}">
                <el-form-item :error="errorMessage">
                    <el-input
                        class="itemControl"
                        v-bind="field"
                        type="text" 
                        placeholder=""
                        :validate-event="false"
                        :disabled="readOnly"
                        :model-value="value"
                    />
                </el-form-item>
            </Field>
        </template>
        <template v-slot:col2>
            <label class="fs-6 fw-bold mb-2">{{ $t("FIELDS.DELIVERY_DATE") }}</label>
                <DatePicker
                    class="itemControl"
                    nameDate="dateOfDelivery" 
                    :disabled="readOnly"
                    placehoderDate="dd/MM/yyyy"
                />
        </template>
    </RowForm>
    <RowForm :twoColums="false" class="paddingTop">
        <template v-slot:default>
            <label class="fs-6 fw-bold mb-2">{{ $t("FIELDS.OBSERVATIONS") }}</label>
            <Field  name="observations" v-slot="{value, field, errorMessage}">
                <el-form-item :error="errorMessage">
                    <el-input
                        class="itemControlLg"
                        v-bind="field"
                        type="textarea" 
                        placeholder="Observaciones"
                        :validate-event="false"
                        :disabled="readOnly"
                        :model-value="value"
                        :rows="3"
                    />
                </el-form-item>
            </Field>
        </template>
    </RowForm>
</ModalForm>
</template>

<script>
import { defineComponent, onBeforeMount, watch, ref, toRefs, computed } from 'vue';
import { Field } from "vee-validate";
import * as Validations from "yup";
import ModalForm from "../../../components/c-modal-form/ModalFormMaster.vue";
import ApiService from "@/core/services/ApiService";
import RowForm from "../../../components/c-form-elements/RowForm.vue";
import SwalMessageService from "@/core/services/SwalMessageService";
import { getMessageError } from '@/core/helpers/messageFromPulmeros';
import DatePicker from '@/components/forms/DatePicker.vue';
import SelectFilterRemote from '../../../components/forms/SelectFilterRemote.vue'

export default defineComponent({
    name: "purchase-order-edit-form",
    components:{
        Field,
        ModalForm,
        RowForm
        , DatePicker
        , SelectFilterRemote
    },
    props: {
        mode: {
            type: String,
            required: false,
            default: "N"
        },
        titleModal: {
            type: String,
            required: false,
            default: "Modificar Orden de Compra"
        },
        currentOpenedItem: {
            type: Object,
            required: false,
            default: () => null
        },
        fnCallBackPersistenceNotifier: {
            type: Function,
            required: false
        },
    },
    setup(props) {
        const { mode, currentOpenedItem } = toRefs(props);
        const messageService = new SwalMessageService();
        const editForm = ref(null);
        const modalForm = ref(null);
        let suppliers = ref([]);
        let txtName = ref("");
        let controller = "purchaseOrders";
        const validationSchema = Validations.object().shape({
            folio: Validations.string().label("Folio")
            , supplierId: Validations.string().required().label("Supplier")
            , dateOfDelivery: Validations.date().required().label("Fecha de Entrega")
            , purchaseOrderSupplierFolio: Validations.string().label("Folio del Proveedor")
            , creditDays: Validations.number().required().label("Días de Crédito")
            , freightNumber: Validations.string().label("Número de Carga")
            , observations: Validations.string().label("Observaciones")
        });

        // onBeforeMount(async() => {
        //     await getComboOptions("suppliers", suppliers);
        // });

        watch(currentOpenedItem, async(nValue) => {
            modalForm.value.$refs.form.setFieldValue("folio", currentOpenedItem.value.folio);
            modalForm.value.$refs.form.setFieldValue("supplierId", currentOpenedItem.value.supplierId);
            await getSupplier(currentOpenedItem.value.supplierId);
        });

        const initialValues = computed(() => {
            let po = {};
            if((mode.value === "M" || mode.value === "V") && currentOpenedItem.value) {
                po.folio = currentOpenedItem.value.folio;
                po.supplierId = currentOpenedItem.value.supplierId;
                po.dateOfDelivery = new Date(currentOpenedItem.value.dateOfDelivery);
                po.purchaseOrderSupplierFolio = currentOpenedItem.value.purchaseOrderSupplierFolio;
                po.creditDays = currentOpenedItem.value.creditDays;
                po.freightNumber = currentOpenedItem.value.freightNumber;
                po.observations = currentOpenedItem.value.observations;
            }
            
            return po;
        });

        const readOnly = computed(() => {
            return mode.value === "V";
        });

        const isEditing = computed(() => {
            return mode.value === "M" || mode.value === "V";
        });

        const comboSupplier = computed(() => { return suppliers.value });

        //FUNCIONES
         const initDefaults = () => {
            modalForm.value.$refs.form.resetForm();
            modalForm.value.$refs.form.setFieldValue("folio", "");
            modalForm.value.$refs.form.setFieldValue("supplierId", "");
            modalForm.value.$refs.form.setFieldValue("dateOfDelivery", "");        
            modalForm.value.$refs.form.setFieldValue("purchaseOrderSupplierFolio", "");    
            modalForm.value.$refs.form.setFieldValue("creditDays", 0);  
            modalForm.value.$refs.form.setFieldValue("freightNumber", "");
            modalForm.value.$refs.form.setFieldValue("observations", "");
        }

        const handleCrudAction = async(values) => {
            if(props.mode === "N") {
                await saveNewItem(values);
            } else if(props.mode === "M") {
                saveUpdatedItem(values);
            }
        }

        const saveNewItem = async(item) => {
            ApiService.post(controller, item).then(res => {
                if(res.data.id) {
                    item.id = res.data.id;
                    item.supplierName = suppliers.value.some(s => s.id == item.supplierId) ? suppliers.value.find(s => s.id == item.supplierId).name : "";
                    modalForm.value.closeModal();
                    messageService.success("La información se guardó exitosamente.");
                    if(props.fnCallBackPersistenceNotifier) {
                        props.fnCallBackPersistenceNotifier(item);
                    }
                }            
            }).catch(reject => {
                getMessageError(reject, messageService, "MessageError");
            });
        }

        const saveUpdatedItem = async(item) => {
            let url = `${controller}/${currentOpenedItem.value.id}`;
            item.id = currentOpenedItem.value.id;
            currentOpenedItem.value.supplierId = item.supplierId;
            item.supplierName = suppliers.value.some(s => s.id == item.supplierId) ? suppliers.value.find(s => s.id == item.supplierId).name : "";
            item.tax = currentOpenedItem.value.tax;
            item.total = currentOpenedItem.value.total;
            await ApiService.put(url, item).then(res => {
                if(res.status == 204) {
                    modalForm.value.closeModal();
                    messageService.success("La información se guardó exitosamente.");
                    if(props.fnCallBackPersistenceNotifier) {
                        props.fnCallBackPersistenceNotifier(item);
                    }
                }            
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const getComboOptions = async(resource, container, parentId = "", filter = "") => {
            let parentParam = parentId.trim() != "" ? `${parentId}/` : "";
            let url = `${resource}/${parentParam}combo?Criteria=${filter}`;
            ApiService.query(url).then(res => {
                container.value = res.data;
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const searchSuppliers = async(filter) => {
            if(filter.trim().length >= 3) {
                await getComboOptions("suppliers", suppliers, "", filter);
            }
        }

        const getSupplier = async(supplierId) => {
            let url = `Suppliers/${supplierId}`;
            ApiService.get(url).then(res => {
                if(res.status == 200) {
                    suppliers.value.push({
                        id: res.data.id,
                        name: res.data.name,
                        code: res.data.code,
                        rfc: res.data.rfc
                    })
                }
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        return {
            editForm,
            modalForm,
            txtName,
            validationSchema,
            initialValues,
            comboSupplier,
            readOnly,
            isEditing,

            //Funciones
            handleCrudAction,
            initDefaults
            , searchSuppliers
        }
    },
})
</script>

<style lang="scss">

    .itemControl {
        width: 272px !important;
    }

    .itemControlLg {
        width: 553px;
    }

    .rowDivControl {
        display: flex;
        justify-content: space-between;
    }

    .itemDivControl {
        display: flex;
        flex-direction: column;
    }

    .padd {
        padding-left: 10px;
    }

    .paddingTop {
        padding-top: 15px;
    }

</style>

<i18n>
{
    "es":{
        "FIELDS": {
            "FOLIO": "Folio",
            "DELIVERY_DATE": "Fecha de Entrega",
            "SUPPLIER": "Proveedor",
            "SUPPLIER_FOLIO": "Folio de Compra del Proveedor",
            "CREDIT_DAYS": "Días de Crédito",
            "FREIGHT_NUMBER": "Número de Flete",
            "OBSERVATIONS": "Observaciones"
        }
    }
}
</i18n>
